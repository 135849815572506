<template >
  <div class="loginPage" >
    <div class="container">
      <div class="forms-container">
        <PaperDisplayer :value="data" />
        <div class="signin-signup">
          <form action="#" class="sign-in-form" v-if="step==1">
            <h2 class="title">SigUp </h2>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Firts Name" v-model="data.firstName" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Last Name" v-model="data.lastName" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="text" placeholder="Phone" v-model="data.phone" />
            </div>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="email" placeholder="Email" v-model="data.email" />
            </div>
            <div class="input-field">
              <i class="fas fa-user"></i>
              <input type="text" placeholder="Username" v-model="data.user"/>
            </div>
            <button class="btn-l solid" type="button" v-on:click="stepAction(1)"> Next </button>
            
            <!--p class="social-text">Or Sign in with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
          <div class="demo-frame sign-in-form" style="width: 500px;"   v-if="step==2">
            <form action="/" method="post"  id="cardForm" autocomplete="off">
              <label class="hosted-fields--label" for="card-number">Card Number</label>
              <div id="card-number" class="hosted-field"></div>
    
              <label class="hosted-fields--label" for="expiration-date">Expiration Date</label>
              <div id="expiration-date" class="hosted-field"></div>
    
              <label class="hosted-fields--label" for="cvv">CVV</label>
              <div id="cvv" class="hosted-field"></div>
    
              <label class="hosted-fields--label" for="postal-code">Postal Code</label>
              <div id="postal-code" class="hosted-field"></div>
    
              <div class="button-container">
              <br>
              <div class="input-wrap col-12" style="text-align: left;">
                <input type="checkbox" class="box" />
                <span class="text" style="font-size:12px;display: block;float: right;width: calc(100% - 20px); padding: 0 0 0 10px;" > Save this CC for future transactions </span>
                <br>
                <input type="checkbox" class="box" v-model="tandc"/>
                <span class="text" style="font-size:12px;display: block;float: right;width: calc(100% - 20px); padding: 0 0 0 10px;" >I agree to the <a :href="publicOrganizationData.termsAndConditions" target="_blank">terms and conditions</a></span>
              </div>
              <br>
              <input v-if="tandc == true" type="submit" class="btn-g show green center" value="Save" style="cursor:pointer;" id="submit" v-on:click="stepAction(2)" />
              <input v-else  class="btn-g show gray center" style="cursor:pointer;" value="Save" disabled />
              </div>
            </form>
          </div> 
          <form v-if="!$router.currentRoute.value.query.data" action="#" >
            <!--button class="btn-l solid" type="button" v-on:click="singUpUser"> Sign up </!--button-->
            <!--p class="social-text">Or Sign up with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
          <form v-else action="#" class="sign-up-form">
            <h2 class="title">Client Sign up</h2>
            <div class="input-field">
              <i class="fas fa-envelope"></i>
              <input type="email" placeholder="Email" v-model="userSingUp" />
            </div>
            <div class="input-field">
              <i class="fas fa-lock"></i>
              <input type="password" placeholder="Password" v-model="passwordSingUp" />
            </div>
            <button class="btn-l solid" type="button" v-on:click="singUpUser"> Sign up </button>
            <!--p class="social-text">Or Sign up with social platforms</!--p>
            <div-- class="social-media">
              <a href="#" class="social-icon">
                <i class="fab fa-facebook-f"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-twitter"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-google"></i>
              </a>
              <a href="#" class="social-icon">
                <i class="fab fa-linkedin-in"></i>
              </a>
            </div-->
          </form>
        </div>
      </div>
      <div class="panels-container">
        <div class="panel left-panel">
          <div class="content">
            <img style="width: 250px;" :src="publicOrganizationData.logoColor[0].url" class="image" alt="" />
            
          </div>
          <!--img src="../../assets/icons/log.svg" class="image" alt="" /-->
        </div>  
        <div class="panel right-panel">
          <div class="content">
            <h3>Lorem</h3>
            
          </div>
          <!--img src="../../assets/icons/register.svg" class="image" alt="" /-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let tokenQuote = null
//AWS Back End
import { smd_getRecordObj, smd_getMyOrganization, smd_listEntityObj, smd_listUserGroups  } from '../../graphql/queries'
import { smd_customerSignUp } from '../../graphql/mutations'
import { API, Auth } from "aws-amplify";
//Component
import PaperDisplayer from '../../components/papers/PaperDisplayer.vue'
import UiButton from '../../components/ui/UiButton.vue'

//Tools
import {popUp} from '../../store/tools'
import Vuex from "vuex";
import Swal from 'sweetalert2'


export default {
  components: { 
    PaperDisplayer,
    UiButton,
  },
  name: "Login",
  data() {
    return {
      data:{},
      step:1,
      //billing
      tandc:false,
    };
  },
  created() {
    if (location.host == 'localhost:8080' ) {
      this.local = true
    }
    //
    this.setUserWeb()
  },
  methods: {
    ...Vuex.mapMutations(["setIdentityId","SetOrganizationID", "SetUser", "SetRows", "setDataUser",'setAttUser', 'setPublicOrganization','setUserPermissions' ,'setPublicOrganizationData','SetOrganizationData','setClasses','SetPermissions']),
    ...Vuex.mapActions(['getPublicOrganization','getRecordLists','getEntities','getProducts']),
    //Tools
    loading(action){
      if (action == 'show') {
        document.getElementById("loading").classList.add('show')
        document.getElementById("body").classList.add("noScroll")
      }else{
        document.getElementById("loading").classList.remove('show')
        document.getElementById("body").classList.remove("noScroll")
      }
    },
    stepAction(step){
      console.log(step);
      switch (step) {
        case 1:
          console.log('step');
          this.step = 2
          this.setPaymentMethods()
          this.loginUser()
        break;
        case 2:
          setTimeout(() => {
            
          }, 1000);
        break;
      }
    },
    async setPaymentMethods(){
      let form = null
      //------
      let pullData = null
      let pushData = null
      let type = null
      try {
        pushData={
          PK: '#SMARTDASH',
          SK: "#ORG#SMARTDASH",
        }
        pullData = await API.graphql({
          query: smd_getRecordObj,
          variables: pushData
        })
        pullData = pullData.data.smd_getRecordObj
        pullData = JSON.parse(pullData.data)
        console.log(pullData);
        type = this.organizationData.paymentMode.toLowerCase() 
      } catch (error) {
        console.log(error);
        //popUp(error, 'cach', 'err')
      }
      //------
      setTimeout(() => {
        //console.clear()
        let authorization = null;
        form = document.getElementById('cardForm')
        if (type == 'production') {
          authorization = pullData.clientsideToken.value
        } else {
          authorization = pullData.sandboxClientsideToken.value;
        }
        braintree.client.create({ authorization: authorization }, 
        function (err, clientInstance) {
          if (err) {
            console.error(err);
            return;
          }
          createHostedFields(clientInstance);
        });
      }, 500);
      function createHostedFields(clientInstance) {
        braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            'input': {
              'font-size': '16px',
              'font-family': 'courier, monospace',
              'font-weight': 'lighter',
              'color': '#555555'
            },
            ':focus': {
              'color': 'black'
            },
            '.valid': {
              'color': '#08a414f7'
            }
          },
          fields: {
            number: {
              selector: '#card-number',
              //placeholder: '4111 1111 1111 1111'
            },
            cvv: {
              selector: '#cvv',
              //placeholder: '123'
            },
            expirationDate: {
              selector: '#expiration-date',
              placeholder: 'MM/YYYY'
            },
            postalCode: {
              selector: '#postal-code',
              //placeholder: '11111'
            }
          }
        }, function (err, hostedFieldsInstance) {
          if (err) {
            console.error(err);
            return;
          }
          var tokenize = function (event) {
            event.preventDefault();
            hostedFieldsInstance.tokenize(function (err, payload) {
              if (err) {
                console.log(err);
                tokenQuote = JSON.stringify({token:null, processorResponse:err })
                return;
              }
              tokenQuote = JSON.stringify({token:payload.nonce, processorResponse:payload })
              //alert('Submit your nonce (' +  payload.nonce + ') to your server here!');
            });
          };
          
          form.addEventListener('submit', tokenize, false);
          //form.on("submit", tokenize, false);
        });
      }
    },
    async setUserWeb(){
      console.log('>>> Usuario: '+this.user);
        Auth.signIn('smd_webuser', '383@JDU45ss#')
        .then((user) => { 
          //Clear Global Variables
          this.SetOrganizationID("Not authorized")
          this.SetOrganizationData("Not authorized")
          this.setPublicOrganization(undefined)
          this.setPublicOrganizationData(undefined)
          //console.clear()
          ///-----

          this.getPublicOrganization()
          this.loading('hidden')
        })
        .catch((err) => console.log(err))
    },
    async loginUser() {
      let pullData = null
      let pushData = null
      try {
        //if (JSON.parse(tokenQuote).token == null) {
        //  return
        //}
        //this.data.token = tokenQuote
        pushData={
          orgPK: this.publicOrganization,
          data: JSON.stringify(this.data),
          originURL: location.href,
        }
        pullData = await API.graphql({
          query: smd_customerSignUp,
          variables: pushData
        })
        console.log(pullData);
        pullData = pullData.data.smd_customerSignUp
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'err')
          loading('hide', 'getItem')
          return
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
    },
  },
  computed:{
    ...Vuex.mapState(["organizationID",'organizationData', 'publicOrganization','user','publicOrganization', 'publicOrganizationData','currentItem','userPermissions'])
  }
};
</script>


<style lang="less">

</style>
<style >


body,
input {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
  max-width: 100%;
}

.forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 0.3s 0.1s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.1s 0.1s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.sign-in-form {
  z-index: 2;
}

.title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
  margin: 10px 0;
  height: 55px;
  border-radius: 0;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.2s;
  font-size: 1.1rem;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
}

.social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 0;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.social-icon:hover {
  color: #4481eb;
  border-color: #4481eb;
}

.btn-l {
  width: 150px;
  background-color: #5995fd;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 0;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn-l:hover {
  background-color: #4d84e2;
}
.panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  
  transition: 0.5s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.image {
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transition-delay: 0.2s;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.panel .content {
  color: #fff;
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.2s;
}

.panel h3 {
  font-weight: 600;
  line-height: 1;
  font-size: 1.5rem;
}

.panel p {
  font-size: 0.95rem;
  padding: 0.7rem 0;
}

.btn-l.transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.right-panel .image,
.right-panel .content {
  transform: translateX(800px);
}

/* ANIMATION */

.container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.container.sign-up-mode .left-panel .image,
.container.sign-up-mode .left-panel .content {
  transform: translateX(-800px);
}

.container.sign-up-mode .signin-signup {
  left: 25%;
}

.container.sign-up-mode form.sign-up-form {
  opacity: 1;
  z-index: 2;
}

.container.sign-up-mode form.sign-in-form {
  opacity: 0;
  z-index: 1;
}

.container.sign-up-mode .right-panel .image,
.container.sign-up-mode .right-panel .content {
  transform: translateX(0%);
}

.container.sign-up-mode .left-panel {
  pointer-events: none;
}

.container.sign-up-mode .right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .container {
    min-height: 800px;
    height: 100vh;
  }
  .signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 0.2s 0.2s ease-in-out;
  }

  .signin-signup,
  .container.sign-up-mode .signin-signup {
    left: 50%;
  }

  .panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .right-panel {
    grid-row: 3 / 4;
  }

  .left-panel {
    grid-row: 1 / 2;
  }

  .image {
    width: 200px;
    transition: transform 0.5s ease-in-out;
    transition-delay: 0.1s;
  }

  .panel .content {
    padding-right: 15%;
    transition: transform 0.5s ease-in-out;
    transition-delay: 0.1s;
  }

  .panel h3 {
    font-size: 1.2rem;
  }

  .panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .btn-l.transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 0.5s ease-in-out;
  }

  .container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .container.sign-up-mode .left-panel .image,
  .container.sign-up-mode .left-panel .content {
    transform: translateY(-300px);
  }

  .container.sign-up-mode .right-panel .image,
  .container.sign-up-mode .right-panel .content {
    transform: translateY(0px);
  }

  .right-panel .image,
  .right-panel .content {
    transform: translateY(300px);
  }

  .container.sign-up-mode .signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .image {
    display: none;
  }
  .panel .content {
    padding: 0.5rem 1rem;
  }
  .container {
    padding: 1.5rem;
  }

  .container:before {
    bottom: 72%;
    left: 50%;
  }

  .container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
</style>
